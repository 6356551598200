import "./index.scss";
import { isNormal } from "./data.js";
const url = "/menu.pdf";
let pdfDoc = null,
  pageNum = 1,
  pageIsRendering = false,
  pageNumIsPending = null;

// const scale = 1,
//   canvas = document.querySelector("#pdf-render"),
//   ctx = canvas.getContext("2d");

const ball = document.querySelectorAll(".wind-element");
const openPdfButton = document.getElementById("openPdfBtn");
ball.forEach((ele, i) => {
  ele.classList.add(`animated-${i}`);
  ele.addEventListener("animationend", () => {
    ele.classList.remove(`animated-${i}`);
    let random = Math.random() * 5 + 3;
    ele.style.animationDuration = `${random}s`;
    void ele.offsetWidth;
    ele.classList.add(`animated-${i}`);
    isNormal[i] = !isNormal[i];
    ele.style.animationDirection = isNormal[i] ? "normal" : "reverse";
  });
});

// // Render the page
// const renderPage = (num) => {
//   pageIsRendering = true;

//   // Get page
//   pdfDoc.getPage(num).then((page) => {
//     // Set scale
//     const viewport = page.getViewport({ scale });
//     canvas.height = viewport.height;
//     canvas.width = viewport.width;

//     const renderCtx = {
//       canvasContext: ctx,
//       viewport,
//     };

//     page.render(renderCtx).promise.then(() => {
//       pageIsRendering = false;

//       if (pageNumIsPending !== null) {
//         renderPage(pageNumIsPending);
//         pageNumIsPending = null;
//       }
//     });

//     // Output current page
//     document.querySelector("#page-num").textContent = num;
//   });
// };

// // Check for pages rendering
// const queueRenderPage = (num) => {
//   if (pageIsRendering) {
//     pageNumIsPending = num;
//   } else {
//     renderPage(num);
//   }
// };

// // Show Prev Page
// const showPrevPage = () => {
//   if (pageNum <= 1) {
//     return;
//   }
//   pageNum--;
//   queueRenderPage(pageNum);
// };

// // Show Next Page
// const showNextPage = () => {
//   if (pageNum >= pdfDoc.numPages) {
//     return;
//   }
//   pageNum++;
//   queueRenderPage(pageNum);
// };
// pdfjsLib.GlobalWorkerOptions.workerSrc =
//   "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

// // Get Document
// pdfjsLib
//   .getDocument(url)
//   .promise.then((pdfDoc_) => {
//     pdfDoc = pdfDoc_;

//     document.querySelector("#page-count").textContent = pdfDoc.numPages;

//     renderPage(pageNum);
//   })
//   .catch((err) => {
//     // Display error
//     const div = document.createElement("div");
//     div.className = "error";
//     div.appendChild(document.createTextNode(err.message));
//     document.querySelector("body").insertBefore(div, canvas);
//     // Remove top bar
//     document.querySelector(".top-bar").style.display = "none";
//   });

// Button Events
// document.querySelector("#prev-page").addEventListener("click", showPrevPage);
// document.querySelector("#next-page").addEventListener("click", showNextPage);

// openPdfButton.addEventListener("click", () => {
//   const pdfUrl = "/menu.pdf";
//   window.open(pdfUrl, "_blank");
// });

openPdfButton.addEventListener("click", () => {
  document.querySelector(".pdfviewer").classList.add("active");
});
document.querySelector(".exit").addEventListener("click", () => {
  document.querySelector(".pdfviewer").classList.remove("active");
});

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

// Load the PDF
pdfjsLib
  .getDocument(url)
  .promise.then((pdfDoc_) => {
    pdfDoc = pdfDoc_;
    // document.querySelector("#page-count").textContent = pdfDoc.numPages;

    // Render all pages
    for (let num = 1; num <= pdfDoc.numPages; num++) {
      renderPage(num);
    }
  })
  .catch((err) => {
    console.error("Error loading PDF:", err);
  });

function renderPage(num) {
  pdfDoc.getPage(num).then((page) => {
    const scale = 1.5; // Adjust scaling if needed
    const viewport = page.getViewport({ scale });

    // Create a canvas for each page
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    // Render the page
    page.render(renderContext).promise.then(() => {
      // Convert canvas to an image
      const img = document.createElement("img");
      img.src = canvas.toDataURL("image/png");
      img.style.width = "100%"; // Fit container width
      img.style.marginBottom = "10px"; // Space between pages

      // Append the image to the container
      document.querySelector(".viewer").appendChild(img);
    });
  });
}
